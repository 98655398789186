.bg-dark.rmdp-wrapper,
.bg-dark .rmdp-month-picker,
.bg-dark .rmdp-year-picker,
.bg-dark .rmdp-time-picker div input,
.rmdp-container .bg-dark.ep-arrow::after {
  background-color: #312f2f;
  color: #c4c7ca;
}

.bg-dark .rmdp-day:not(.rmdp-deactive),
.bg-dark .rmdp-time-picker div .rmdp-am,
.bg-dark .rmdp-header-values,
.bg-dark .rmdp-panel-header {
  color: #c4c7ca;
}

.bg-dark .rmdp-day.rmdp-range {
  color: white;
}

.bg-dark .rmdp-panel-body li {
  color: #f5f5f5;
}

.bg-dark .rmdp-day.rmdp-deactive,
.bg-dark .rmdp-day.rmdp-disabled {
  color: #87898b;
}

.rmdp-container .bg-dark.ep-arrow[direction="top"] {
  border-bottom: 1px solid #312f2f;
}

.rmdp-container .bg-dark.ep-arrow[direction="left"] {
  border-right: 1px solid #312f2f;
}

.rmdp-container .bg-dark.ep-arrow[direction="right"] {
  border-left: 1px solid #312f2f;
}

.rmdp-container .bg-dark.ep-arrow[direction="bottom"] {
  border-top: 1px solid #312f2f;
}
