.rc-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.rc-dialog-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.rc-dialog-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}
.rc-dialog-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.rc-dialog-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: 21px;
  position: absolute;
  right: 20px;
  top: 12px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
  text-decoration: none;
}
.rc-dialog-close-x:after {
  content: '×';
}
.rc-dialog-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  text-decoration: none;
}
.rc-dialog-title {
  margin: 0;
  line-height: 1.42857143;
}
.rc-dialog-body {
  position: relative;
  padding: 15px;
}
.rc-dialog-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.rc-dialog-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.rc-dialog-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.rc-dialog-footer .btn-block + .btn-block {
  margin-left: 0;
}
@media (min-width: 768px) {
  .rc-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .rc-dialog-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
.rc-dialog-slide-fade-enter,
.rc-dialog-slide-fade-appear {
  transform: translate(0, -25%);
}
.rc-dialog-slide-fade-enter,
.rc-dialog-slide-fade-appear,
.rc-dialog-slide-fade-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-play-state: paused;
}
.rc-dialog-slide-fade-enter.rc-dialog-slide-fade-enter-active,
.rc-dialog-slide-fade-appear.rc-dialog-slide-fade-appear-active {
  animation-name: rcDialogSlideFadeIn;
  animation-play-state: running;
}
.rc-dialog-slide-fade-leave.rc-dialog-slide-fade-leave-active {
  animation-name: rcDialogSlideFadeOut;
  animation-play-state: running;
}
@keyframes rcDialogSlideFadeIn {
  0% {
    transform: translate(0, -25%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes rcDialogSlideFadeOut {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -25%);
  }
}
.rc-dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(55, 55, 55, 0.6);
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 1050;
}
.rc-dialog-mask-hidden {
  display: none;
}
.rc-dialog-fade-enter,
.rc-dialog-fade-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.rc-dialog-fade-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}
.rc-dialog-fade-enter.rc-dialog-fade-enter-active,
.rc-dialog-fade-appear.rc-dialog-fade-appear-active {
  animation-name: rcDialogFadeIn;
  animation-play-state: running;
}
.rc-dialog-fade-leave.rc-dialog-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}
@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
